
import DataTable, { Column } from '@/components/DataTable/DataTable.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Languages',
  data(): { columns: Column[]} {
    return {
      columns: [
        {
          key: 'countryCode',
          title: 'Country code',
          canSort: true,
        },
        {
          key: 'locale',
          title: 'Locale',
          canSort: true,
        },
        {
          key: 'isActive',
          title: 'Status',
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'languages',
    ]),
  },
  components: {
    Page,
    DataTable,
    PageTop,
  },

})
